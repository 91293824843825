// Migrated
<template>
  <LayoutWrapper>
    <Header />
    <slot class="layout-body" />
    <template #footer>
      <LazyFooter />
    </template>
  </LayoutWrapper>
</template>

<script setup>
const { t } = useI18n()

const { getLocaleMessages: localeMessages } = useLocaleStore()

useHead({
  titleTemplate: `%s - ${t('siteTitleBrand')}`,
  // link: [{
  //   rel: 'icon',
  //   type: 'image/x-icon',
  //   href: () => t('favicon')
  // }],

  script: [{
    innerHTML: () => localeMessages.organizationData,
    type: 'application/ld+json',
  }],

  meta: () => [
    {
      hid: 'author',
      name: 'author',
      content: () => t('siteTitleBrand'),
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: () => t('siteTitleBrand'),
    },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: () => t('siteTitleBrand'),
    },
  ],
})
</script>
